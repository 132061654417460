<template>
  <ion-header>
    <ion-toolbar>
      <ion-title slot="start">
        <ion-text color="primary" class="logo">Freunden Helfen</ion-text>
      </ion-title>
      <ion-title class="text-center">
        <ion-text v-if="title">{{ title }}</ion-text>
      </ion-title>
      <ion-title slot="end">
        <div v-if="auth" class="align-row align-items-center justify-end">
          <ion-avatar slot="start" class="header-avatar">
            <img src="https://via.placeholder.com/100">
          </ion-avatar>
          <ion-button @click="$router.push('/profile')">
            <ion-icon slot="start" :icon="personOutline"></ion-icon>
            Jane Doe
          </ion-button>
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { IonHeader, IonToolbar, IonTitle, IonText, IonButton, IonIcon, IonAvatar } from '@ionic/vue';
import { personOutline } from 'ionicons/icons';

import { inject } from 'vue'

export default {
  name: 'PageHeader',
  components: { IonHeader, IonToolbar, IonTitle, IonText, IonButton, IonIcon, IonAvatar },
  props: {
    title: String
  },
  setup () {
    const auth = inject('auth');
    return {
      personOutline,
      auth
    }
  }
}
</script>

<style scoped>
.header-avatar {
  max-height: 36px;
  max-width: 36px;
}
</style>
