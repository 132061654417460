
import { IonPage, IonContent, IonItem, IonInput, IonLabel, IonButton, IonIcon, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { logInOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

import { inject } from 'vue'

export default  {
  name: 'PageLogin',
  components: { IonContent, IonPage, IonItem, IonInput, IonLabel, IonButton, IonIcon, IonGrid, IonCol, IonRow, PageHeader },
  setup() {
    const auth = inject('auth');
    const login = inject('login');
    return {
      logInOutline,
      auth,
      login
    }
  }
}
