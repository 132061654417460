<template>
  <ion-page>
    <page-header />
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-md="6" size-lg="4" offset="0" offset-md="3" offset-lg="4">

            <div v-if="!auth" class="login">
              <h2>Anmelden</h2>
              <form action="">
                <ion-item class="mt-1">
                  <ion-label position="stacked">Benutzername</ion-label>
                  <ion-input></ion-input>
                </ion-item>
                <ion-item class="mt-1">
                  <ion-label position="stacked">Password</ion-label>
                  <ion-input type="password"></ion-input>
                </ion-item>
                <ion-button class="mt-1" @click="login()">
                  Login
                  <ion-icon slot="end" :icon="logInOutline"></ion-icon>
                </ion-button>
              </form>
            </div>

            <div v-else>
              Redirecting...
            </div>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonItem, IonInput, IonLabel, IonButton, IonIcon, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { logInOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

import { inject } from 'vue'

export default  {
  name: 'PageLogin',
  components: { IonContent, IonPage, IonItem, IonInput, IonLabel, IonButton, IonIcon, IonGrid, IonCol, IonRow, PageHeader },
  setup() {
    const auth = inject('auth');
    const login = inject('login');
    return {
      logInOutline,
      auth,
      login
    }
  }
}
</script>
