
import { IonHeader, IonToolbar, IonTitle, IonText, IonButton, IonIcon, IonAvatar } from '@ionic/vue';
import { personOutline } from 'ionicons/icons';

import { inject } from 'vue'

export default {
  name: 'PageHeader',
  components: { IonHeader, IonToolbar, IonTitle, IonText, IonButton, IonIcon, IonAvatar },
  props: {
    title: String
  },
  setup () {
    const auth = inject('auth');
    return {
      personOutline,
      auth
    }
  }
}
